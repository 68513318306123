@charset "utf-8";

@import "../node_modules/bulma/sass/utilities/initial-variables.sass";
// Custom variables (https://bulma.io/documentation/customize/variables/#initial-variables)

@import "../node_modules/bulma/sass/utilities/derived-variables.sass";
// Custom variables (https://bulma.io/documentation/customize/variables/#variables)

@import "../node_modules/bulma/bulma.sass";

@import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";

html,
body {
  height: 100%;
  font-size: 0.9rem;
  -moz-osx-font-smoothing: grayscale;
}

.footer {
  margin-top: 100px;
}
